html, body, #root, .App {
  height: 100%;
  background-color: #000000;
}

.Title {
  color: yellow;
  text-align: center;
  font-size: calc(80px);
}

.SubTitle {
  color: white;
  text-align: center;
  font-size: calc(40px);
}

.Images {
  margin-top: 10px;
  text-align: center;
}

.Image {
  display: inline-block;
  margin: 0 30px 50px 30px;  
}

.AppStoreBadge {
  text-align: center;
  margin-bottom: 50px;
}

.TwitterLinkContainer {
  text-align: center;  
  color: white;
  margin-top: 50px;
}

.TwitterLinkTitle {
  font-size: initial;
}

.TwitterLink {
  color: white;
  padding-bottom: 40px;
}

@media only screen and (max-width: 600px) {
  .Title {
    font-size: calc(40px + 2vmin);
  }

  .SubTitle {
    font-size: calc(20px + 2vmin);
  }
}